import React from 'react';

const MapIframe = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14614.233442576106!2d-46.5498572!3d-23.6917467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4312d6667639%3A0x2be5dca9a9e96469!2sParabank!5e0!3m2!1spt-BR!2sbr!4v1716220133056!5m2!1spt-BR!2sbr"
      style={{ border: 0,
        width: '380px',
        height: '270px',
        borderRadius: '0',
        marginTop: '0'}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default MapIframe;

