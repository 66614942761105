import React, { useState } from "react";
import icon1 from '../../imagens/icon/frontend.png';
import html from '../../imagens/icon/html.png';
import css from '../../imagens/icon/css.png';
import javascript from '../../imagens/icon/javascript.png';
import bootstrap from '../../imagens/icon/bootstrap.png';
import "./Cards.css";

const Card = () => {
    const [isFlipped, setIsFlipped] = useState(false);

    const toggleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className={`card-container ${isFlipped ? "is-flipped" : ""}`}>
            <div className="card-front">
                <img src={icon1} alt="imagem front-end" className="card-img" />
                <h2 className="card-title">front-end</h2>
                <p>O desenvolvimento front-end, que engloba HTML, CSS, Bootstrap e JavaScript, 
                é fundamental para criar interfaces web interativas e visualmente atraentes.</p>
                <button onClick={toggleFlip} className="toggle-images-btn">
                    {isFlipped ? "Esconder Imagens" : "Ler mais"}
                </button>
            </div>
            <div className="card-back">
                <div className="images-container">
                    <div className="container-img-item">
                        <img src={html} alt="HTML" className="image-item" />
                        <p>HTML</p>
                    </div>
                    <div className="container-img-item">
                        <img src={css} alt="CSS" className="image-item" />
                        <p>CSS</p>
                    </div>
                    <div className="container-img-item">
                        <img src={bootstrap} alt="Bootstrap" className="image-item" />
                        <p>Bootstrap</p>
                    </div>
                    <div className="container-img-item">
                        <img src={javascript} alt="Javascript" className="image-item" />
                        <p>Javascript</p>
                    </div>
                </div>
                <button onClick={toggleFlip} className="toggle-images-btn">
                    Voltar
                </button>
            </div>
        </div>
    );
};

export default Card;
